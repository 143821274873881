<template>
  <div style="margin: -15px -15px">
    <div class="help-right-top">
      关键字: <a-input
        v-model="keyword_val"
        style="width: 300px"
        placeholder="请输入关键字" />
      <span style="margin-left: 30px">关键字: </span>
      <a-select
          v-model="type"
          placeholder="请选择类型"
          style="width: 450px;margin-left: 5px"
      >
        <a-select-option
            v-for="(type,index) in templateList"
            :key="index + 'rest_compere'"
            v-model="type.type">
          {{type.typeName}}
        </a-select-option>
      </a-select>
      <a-button
          type="primary"
          @click="selectBtn"
          style="margin-left: 20px">查询</a-button>
      <a-button
          @click="restBtn"
          style="margin-left: 10px">重置</a-button>
    </div>
    <div style="padding-top: 20px;width: 100%">
      <a-table
          :pagination="false"
          style="width: 98%;margin: auto"
          :columns="columns"
          :rowKey='record=>record.id'
          :data-source="tableList">
         <!--阅读量-->
        <span slot="customCenter">
          <div class="customSty">
            <div class="title">阅读量</div>
          <div>
            <div >
              <a-icon
                  @click="readsUp"
                  class="icon" type="caret-up" />
            </div>
            <div style="margin-top: -10px">
              <a-icon
                  @click="readsDown"
                  class="icon" type="caret-down" />
            </div>
          </div>
          </div>
        </span>
        <!--有用量-->
        <span slot="customUseful">
          <div class="customSty">
            <div class="title">有用量</div>
          <div>
            <div >
              <a-icon
                  @click="usefulUp"
                  class="icon" type="caret-up" />
            </div>
            <div style="margin-top: -10px">
              <a-icon
                  @click="usefulDown"
                  class="icon" type="caret-down" />
            </div>
          </div>
          </div>
        </span>
        <!--无用量-->
        <span slot="customUseless">
          <div class="customSty">
            <div class="title">无用量</div>
          <div>
            <div >
              <a-icon
                  @click="uselessUp"
                  class="icon" type="caret-up" />
            </div>
            <div style="margin-top: -10px">
              <a-icon
                  @click="uselessDown"
                  style="cursor: pointer;color: #8c8a8a;" type="caret-down" />
            </div>
          </div>
          </div>
        </span>
      </a-table>
      <!--      分页-->
      <div style="width: 100%">
        <div class="pageClass">
          <a-pagination
              :default-current="page_no"
              show-quick-jumper
              @change="pageChange"
              :total="total"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {get_help_center_list} from "../../../service/MedicalConference";

export default {
  data(){
    return{
      keyword_val:'',
      type:'',
      templateList:[
        {
          id:1,
          typeName:'全部类型',
          type:'',
        },{
          id:2,
          typeName:'电脑客户端-Mac',
          type:'mac',
        },{
          id:3,
          typeName:'手机APP-苹果(IOS)',
          type:'ios',
        },{
          id:4,
          typeName:'电脑客服端-Windows',
          type:'win',
        },{
          id:5,
          typeName:'手机APP-安卓(Android)',
          type:'android',
        },
      ],
      columns:[
        {
          title: '标题',
          dataIndex: 'title',
          key: 'title',
          width:'65%',
        },
        {
          dataIndex: 'reads',
          key: 'reads',
          align:'center',
          slots: { title: 'customCenter' },
        },
        {
          dataIndex: 'useful',
          key: 'useful',
          align:'center',
          slots: { title: 'customUseful' },
        },
        {
          dataIndex: 'useless',
          key: 'useless',
          align:'center',
          slots: { title: 'customUseless' },
        },
      ],
      tableList:[],
      page_no:1,
      total:0,
    }
  },
  created() {
    this.$store.dispatch('setManageHeaderTitle',"帮助中心内容统计")
    this.getList()
  },
  methods:{
    //获取接口列表
    //aType,  "mac","ios"，"win","android"
    // isAsc,  true 升序  false 降序
    // orderByField   "reads": 阅读量,"useful": 有用量,"useless": 无用量,
    async getList(page_no,title,aType,isAsc,orderByField) {
      this.spinning = true
      let data = {
        title:title,
        page_no:page_no,
        aType:aType,
        isAsc:isAsc,
        orderByField:orderByField
      }
      const response = await get_help_center_list(data)
      if(response.code === 0){
        this.tableList = response.data.rows
        this.total = response.data.count
      }else {
        this.$message.warning("失败"+response.message)
      }
      this.spinning = false
    },
    //阅读量
    readsUp(){
      let isAsc = true
      let orderByField = "reads"
      this.getList(this.page_no,this.keyword_val,this.type,isAsc,orderByField)
    },
    readsDown(){
      let isAsc = false
      let orderByField = "reads"
      this.getList(this.page_no,this.keyword_val,this.type,isAsc,orderByField)
    },
    //有用量
    usefulUp(){
      let isAsc = true
      let orderByField = "useful"
      this.getList(this.page_no,this.keyword_val,this.type,isAsc,orderByField)
    },
    usefulDown(){
      let isAsc = false
      let orderByField = "useful"
      this.getList(this.page_no,this.keyword_val,this.type,isAsc,orderByField)
    },
    //无用量
    uselessUp(){
      let isAsc = true
      let orderByField = "useless"
      this.getList(this.page_no,this.keyword_val,this.type,isAsc,orderByField)
    },
    uselessDown(){
      let isAsc = false
      let orderByField = "useless"
      this.getList(this.page_no,this.keyword_val,this.type,isAsc,orderByField)
    },
    selectBtn(){
      this.getList(this.page_no,this.keyword_val,this.type)
    },
    restBtn(){
      this.keyword_val = ''
      this.type = ''
      this.getList(this.page_no,this.keyword_val,this.type)
    },
    pageChange(page_no){
      this.page_no = page_no
      this.getList(page_no,this.keyword_val)
    },
  }
}
</script>

<style lang="scss" scoped>
.help-right-top{
  padding-top: 10px;
  width: 100%;
  height: 65px;
  border-bottom: 10px solid #f5f5f5;
  padding-left: 30px;
}
.pageClass{
  width: 100%;
  text-align: right;
  float: right;
  margin-top: 15px;
  margin-right: 10px;
}
.customSty{
  display: flex;height: 30px;
  .title{
    margin-top:5px;margin-right: 8px;
  }
  .icon{
    cursor: pointer;color: #8c8a8a;
  }
}
</style>